<script>
import LoadingIcon from './LoadingIcon.vue'
    export default {
        components: { LoadingIcon },
        props: {
            messages: Array,
            loading: Object
        },        
    }
</script>

<template>
    <div class="process-message-comp">
        <div v-for="message in messages" :key="message.key" class="message-item">
            <div v-if="loading[message.key]"  class="show">
                <LoadingIcon />
                <div class="label mt16">{{message.value}}</div>
            </div>
        </div>

    </div>
</template>


<style lang="sass" scoped>
.process-message-comp
    width: 100%
    height: 100%
    background: rgba(255,255,255,.95)
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 0
    left: 0
    z-index: 100

    .message-item
        justify-content: center
        align-items: center
        display: flex
        text-align: center
        font-size: $fzSm
        font-weight: bold
        color: $cG5
        


</style>