<script>
import ProcessMessages from '../ProcessMessages.vue'
import Callout from '../Callout.vue'
import Btn from '../form/Btn.vue'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { UsersMixin } from '@/mixins/UsersMixin';


    export default {
        components: {StripeElementCard, Btn, Callout, ProcessMessages},   
        mixins: [UsersMixin],
        
        data() {
            return {
                publishKey: process.env.VUE_APP_STRIPE_PK,
                tokenData: {name: null},
                cardName: null,
                working: {
                    addCard: false,
                    tryPay: false,
                    updateCard: false
                },
                processMessages: [
                    {value: "💳 Adicionando novo cartão...", key: "addCard"},
                    {value: "😎 Atualizando assinatura...", key: "tryPay"},
                ],
                error: null
            }
        },

        watch: {
            cardName(value) {
                this.tokenData = {name: value}
            }
        },

        methods: {

            /**
             * Submit the for to Stripe API and get the
             * card token to attach on customer
             */
            submit () {
                if(!this.cardName) return this.error = "Adicione seu nome como aparece no cartão"

                this.error = null                
                this.working.updateCard = true
                this.working.addCard = true                
                this.$refs.elementRef.submit()                
            },


            /**
             * Send the Card Token to API and try to attach
             * to current user as a new payment source
             */
            async tokenCreated (token) {
                this.working.tryPay = true
                this.working.addCard = false
                try {
                    await this.addPaymentSource(token.id)
                    this.$router.go()
                } catch (error) {
                    this.error = error.response.data.error.message
                    this.working.tryPay = false
                    this.working.updateCard = false
                }
            },


            /**
             * Handle errors on token generation
             */
            tokenError(){
                this.working.updateCard = false
                this.working.addCard = false   
            }
        },
    }
</script>

<template>
    <div class="payments-update-modal">
        <div>
            <ProcessMessages v-if="working.updateCard" :messages="processMessages" :loading="working" />
        <div class="credit-card-info mb32">
            <input v-model="cardName" type="text" class="stripe-extra-field mb16" placeholder="Nome impresso no cartão">
            <stripe-element-card ref="elementRef" :pk="publishKey" :tokenData="tokenData" @token="tokenCreated" @error="tokenError" :hidePostalCode="true" value="{name: 'Test name'}"/>
            <Callout v-if="error" class="mt16" type="danger">{{error}}</Callout>
        </div>
        </div>
        <Btn @click.native="submit" size="sm" :loading="working.updateCard">Salvar cartão</Btn>
    </div>
</template>


<style lang="sass">
.stripe-extra-field
    border: 0
    color: #32325d
    font-size: 16px
    width: 100%
    padding: 10px 12px
    border-bottom: 1px solid #CFD8DC

    &::placeholder
        color: #90A4AE

    &:focus, &:active
        outline: 0

.payments-update-modal
    #stripe-element-form 
        #stripe-element-errors
            color: $cDanger
            margin-top: $mgXs



</style>