<script>
import TagLabel from '../components/TagLabel.vue'
import ActionRow from '../components/ActionRow.vue'
import PaymentsUpdateModal from '../components/modal/PaymentsUpdateModal.vue'
import CancelSubscriptionModal from '../components/modal/CancelSubscriptionModal.vue'
import Callout from '../components/Callout.vue'
import SectionUsersTable from './sections/SectionUsersTable.vue'
import SwitchField from '../components/form/SwitchField.vue'
import Modal from '../components/Modal.vue'
import Btn from '../components/form/Btn.vue'
import TopHeader from '@/components/TopHeader.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import TextField from '@/components/form/TextField.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';
import { ValidateMixin } from '@/mixins/ValidateMixin';
import { UsersMixin } from '@/mixins/UsersMixin';

export default {
    name: 'userSettings',
    mixins: [UtilitiesMixin, UsersMixin, ValidateMixin],
    components: {TopHeader, TextField, SectionHeader, Btn, Modal, Modal, SwitchField, SectionUsersTable, Callout, CancelSubscriptionModal, PaymentsUpdateModal, ActionRow, TagLabel},

    mounted () {
        if(this.$route.query.update_from) this.trackPlanChanges()
    },

    data() {
        return {
            working: {
                portalUrl: false,
                updateProfile: null
            },
            error: {
                name: null,
                email: null
            },
            modals: {
                cancelSubscription: false,
                updateCard: false
            }
        }
    },


    computed: {        
        plan(){
            const plansData = {            
                business: {
                    name: "Plano Empresas",
                    price: "247,00",
                    limit: "15.000"
                },
                pro: {
                    name: "Plano PRO",
                    price: "87,00",
                    limit: "5.000"
                },
                solo: {
                    name: "Plano Solo",
                    price: "57,00",
                    limit: "1.000"
                },
                free: {
                    name: "Plano Grátis",
                    price: "0,00",
                    limit: "100"
                },
            }
            
            let plan

            if(!this.$user.subscriptions.plan_name) plan = 'free'
            else plan = this.$user.subscriptions.plan_name
            return plansData[plan]
        },

        hasActiveSubscription(){
            if(this.$user?.subscriptions?.gateway_subscription_id) return true
            return false
        },

        subscriptionStatus(){
            const pastDue = ["past_due", "unpaid"]
            if(pastDue.includes(this.$user.subscriptions?.status)) return "past_due"
            if(this.$user.subscriptions?.status == "locked") return "locked"
            if(this.$user?.subscriptions?.id) return "active"
            return null
        }


            
   
    },

    methods: {
        openCancelModal(){ this.modals.cancelSubscription = true},
        closeCancelModal(){ this.modals.cancelSubscription = false},

        updateCurrentUser(){
            this.error.name = null
            this.error.email = null

            if(this.isEmpty(this.$user.name)) this.error.name = "Coloque seu nome"
            if(this.isEmpty(this.$user.email) || this.isEmail(this.$user.email) != true) this.error.email = "Coloque um e-mail válido"
            
            if(!this.error.name && !this.error.email){
                this.working.updateProfile = true
                this.updateUser(this.$user)
                .then(response =>{
                    this.working.updateProfile = false                    
                })
            } 

        },

        trackPlanChanges(){
            //// New Sub
            if(this.$user.plan_level > this.$route.query.update_from && this.$route.query.update_from == 0){
                this.$trackEvent.mixpanel("New Subscription")            
                this.$trackEvent.gtm("New Subscription")
            }

            //// Upgrade
            if(this.$user.plan_level > this.$route.query.update_from && this.$route.query.update_from != 0){
                this.$trackEvent.mixpanel("Upgrade Subscription", this.$user)
            }

            //// Downgrade
            if(this.$user.plan_level < this.$route.query.update_from && this.$route.query.update_from != 0){
                this.$trackEvent.mixpanel("Downgrade Subscription", this.$user)
            }

        },

        updatePlanLink() {
            if(this.hasActiveSubscription) this.redirectToPortal()
            else this.redirectToUpgrade()            
        },

        redirectToPortal(){
            this.working.portalUrl = true
            this.$axios.post(process.env.VUE_APP_API_URL + 'subscription/portalsession/' + this.$user.id)
                .then(response =>{
                    window.location.href = response.data.data.url
                })
        },

        redirectToUpgrade(){
            this.$router.push({name: 'Upgrade'})
        }
        
    },
    



}
</script>

<template>
    <div class="page-settings">

        <TopHeader />

        <!-- Dashboard -->
        <main class="container container-sm main-content mt64">
            <div class="page-header">
                <h1 class="page-title title-xl mb8">Sua conta</h1>
                <p class="page-description title-sm light">Configurações da sua conta.</p>
            </div>

            <Modal ref="update-plan" title="Alterar plano" >
                Entre em contato com o suporte para alterar seu plano. Use o balão de mensagens no canto inferior direito para isso.
            </Modal>

            <section class="mt64">
                <SectionHeader title="Plano atual" />

                <Callout v-if="subscriptionStatus == 'past_due'" class="mb16 subscription-fail" type="warning" emoji="💳">
                    <h2 class="h2 mb8">Não conseguimos renovar sua assinatura :(</h2>
                    <p>Isso pode ocorrer devido a um cartão de crédito vencido ou outro problema no seu meio de pagamento. Você pode:</p>
                    <ul class="mt16">
                        <li>Atualizar seu meio de pagamento</li>
                        <li>Cancelar sua assinatura e voltar para o plano grátis</li>
                    </ul>
                </Callout>

                <Callout v-if="subscriptionStatus == 'locked'" class="mb16 mt16 subscription-fail"  emoji="💳">
                    <h2 class="h2 mb8">Infelizmente não conseguimos renovar sua assinatura :(</h2>
                    <p>Isso pode ocorrer devido a um cartão de crédito vencido ou outro problema no seu meio de pagamento. Você pode:</p>
                    <div style="flex-direction:column" class="mt16">
                        <Btn :link="{name: 'Upgrade'}" class="mb16">Assinar novamente um plano</Btn>
                        <Btn @click.native='openCancelModal' type="outline">Cancelar e retornar ao plano gratuito</Btn>
                    </div>
                </Callout>

                <div class="plan-data">
                    <div class="plan-content">
                        <div class="plan-name">{{plan.name}} <TagLabel v-if="subscriptionStatus == 'locked'" class="ml8" type="warning">Não renovado</TagLabel></div>
                        <div class="plan-use">{{$user.limits.consume.respondents.used}}/{{plan.limit}} respostas recebidas</div>
                        <div class="plan-use">O limite atualiza no 1º dia do mês</div>
                    </div>
                    <div v-if="subscriptionStatus == 'active'" class="plan-update">
                        <Btn @click.native='updatePlanLink' :loading='working.portalUrl' size="sm" class="mb8">Alterar seu Plano</Btn>
                        <Btn  @click.native='modals.updateCard = true' type="secondary" size="sm">Atualizar cartão</Btn>                        
                    </div>
                    <div v-else-if="subscriptionStatus == 'past_due'" class="plan-update">
                        <Btn  @click.native='modals.updateCard = true' type="primary" >Atualizar cartão</Btn>                        
                    </div>
                    <div v-if="!subscriptionStatus" class="plan-update">
                        <Btn @click.native='updatePlanLink' :loading='working.portalUrl' type="positive" size="sm" class="mb8">Assinar um plano</Btn>
                    </div>
                </div>

                <div v-if="subscriptionStatus && subscriptionStatus !='locked'" class="cancel-subscriptio mt32">
                    <ActionRow title="Cancelar assinatura" description="Cuidado, isso pode bloquear os seus formulários atuais">
                        <Btn @click.native='openCancelModal' type="danger" size="xs">Cancelar assinatura</Btn>
                    </ActionRow>
                </div>

                <Modal :active="modals.cancelSubscription" title="Cancelar assinatura?" @modal-close="closeCancelModal">
                    <CancelSubscriptionModal @modal-close="closeCancelModal" />
                </Modal>

                <Modal title="Atualizar cartão de crédito" :active="modals.updateCard" @modal-close="modals.updateCard = false">
                    <PaymentsUpdateModal @modal-close="modals.updateCard = false" />
                </Modal>

                <SectionHeader title="Dados pessoais" />

                <div class="form-group mb16">
                    <label for="name" class="form-label">Nome</label>
                    <TextField v-model="$user.name" id="name" :data="{}" value="teste" :error="error.name" />
                </div>
 
                <div class="form-group mb16">
                    <label for="email" class="form-label">E-mail</label>
                    <TextField v-model="$user.email" id="email" :data="{}" value="teste" :error="error.email" />
                </div>

                <Btn @click.native="updateCurrentUser()" :loading="working.updateProfile" size="sm">Atualizar</Btn>

                <SectionHeader title="Segurança" />

                <div class="form-group mb16">
                    <Btn :link="{name: 'Forgotpassword'}" type="secondary" size="sm">Solicitar alteração de senha</Btn>
                </div>

            </section>
        </main>

    </div>
</template>



<style lang="sass">

.subscription-fail
    .h2
        font-size: $fzSm

    ul
        list-style-type: disc
        padding-left: $mgSm        
        
        li
            line-height: 160%

.main-content
    padding-bottom: 64px

.form-group
    width: 100%
    max-width: 400px
    

.form-label
    display: inline-block
    margin-bottom: $mgXs

.plan-data
    line-height: 160%
    padding: $mg
    background: $cG2
    border-radius: 4px
    display: flex
    justify-content: space-between
    align-items: center

    .plan-name
        font-size: $fzLg
        font-weight: bold

    .plan-update
        display: flex
        flex-direction: column
        align-items: flex-end

// .users-section
//     .user-item
//         font-size: $fzXs
//         display: flex
//         align-items: center
//         margin-bottom: $mgXs
//         padding-bottom: $mgSm
//         border-bottom: 1px solid $cG2

//         .user-name
//             width: 200px

//         .user-email
//             width: auto
        
//         .user-actions    
//             width: 100px
//             margin-left: auto
//             display: flex
//             justify-content: flex-end

//     .users-permission-modal
//         .form-item
//             display: flex
//             justify-content: space-between
//             margin-bottom: $mgXs



</style>