<script>
import TextField from '../../components/form/TextField.vue'
import TagLabel from '../../components/TagLabel.vue'
import SwitchField from '../../components/form/SwitchField.vue'
import Btn from '../../components/form/Btn.vue'
    import Modal from '../../components/Modal.vue'
    export default {
        components: { Modal, Btn, SwitchField, TagLabel, TextField },
        name: "SectionUsersTable",
        mounted () {
            this.getUsers()
        },
        data() {
            return {
                users: null,
                newuser: {
                    name: null,
                    email: null
                }
            }
        },
        methods: {
            getUsers() {
                this.$axios.get(this.$api + 'team/' + "2")
                .then(response => {
                    this.users = response.data.data.users
                })
            },

            addUser(){
                const userData = {
                    name: this.newuser.name,
                    email: this.newuser.email,
                }
            }
        },
    }
</script>

<template>
    <div class="section-users-table">
        <ul v-if="users" class="users-list">
            <li v-for="user in users" :key="user.id" class="user-item">
                <div class="user-name">{{user.name}}</div>
                <div class="user-email">{{user.email}}</div>
                <div class="user-actions">
                    <TagLabel v-if="user.id == $user.tenant_id" color="#263238">Dono</TagLabel>                    
                    <Btn v-else type="secondary" size="xs">Permissões</Btn>
                </div>
            </li>
            <li class="user-item new-user mt16">
                <TextField v-model="newuser.name" size="sm" :data="{}" placeholder="Nome" class="mr8" />
                <TextField v-model="newuser.email" size="sm" type="email" :data="{}" placeholder="E-mail" class="mr8" />
                <Btn @click.native="addUser" size="xs">Adicionar</Btn>
            </li>
        </ul>


        <Modal title="Editar usuário" :active="false" class="users-permission-modal">
            <ul class="forms-list mb32">
                <li class="form-item">
                    <div class="form-title">Form do Owner</div>
                    <div class="form-user-status"><SwitchField /></div>
                </li>
                <li class="form-item">
                    <div class="form-title">Form do Owner</div>
                    <div class="form-user-status"><SwitchField /></div>
                </li>
                <li class="form-item">
                    <div class="form-title">Form do Owner</div>
                    <div class="form-user-status"><SwitchField /></div>
                </li>
                <li class="form-item">
                    <div class="form-title">Form do Owner</div>
                    <div class="form-user-status"><SwitchField /></div>
                </li>
                <li class="form-item">
                    <div class="form-title">Form do Owner</div>
                    <div class="form-user-status"><SwitchField /></div>
                </li>
            </ul>
            <Btn size="sm">Salvar usuário</Btn>
        </Modal>

    </div>
</template>



<style lang="sass" scoped>
.section-users-table
    .user-item
        font-size: $fzXs
        display: flex
        align-items: center
        margin-bottom: $mgXs
        padding-bottom: $mgSm
        border-bottom: 1px solid $cG2

        .user-name
            width: 200px

        .user-email
            width: auto
        
        .user-actions    
            width: 100px
            margin-left: auto
            display: flex
            justify-content: flex-end

    .users-permission-modal
        .form-item
            display: flex
            justify-content: space-between
            margin-bottom: $mgXs

</style>