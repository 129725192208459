<script>
import Callout from '../Callout.vue'
import Btn from '../form/Btn.vue'
import { UsersMixin } from '@/mixins/UsersMixin';

    export default {
        components: { Btn, Callout },
        mixins: [UsersMixin],

        data() {
            return {
                working: {cancel: false},
                error: {cancel: false}
            }
        },
        methods: {
            modalClose() {
                this.$emit('modal-close')                                
            },

            async confirmCancelSubscription(){
                this.working.cancel = true
                try {
                    const cancel = await this.cancelSubscription(this.$user)                    
                    if(cancel.deleted_at) window.location = "https://respondi.app/leMPQs6P"
                    else this.error.cancel = true                    
                } catch (error) {
                    this.error.cancel = true                    
                }
            }
        },
        
    }
</script>

<template>
    <div class="cancel-subscription-modal-comp">
        <h2 class="h2">😢 Ao cancelar sua assinatura...</h2>
        <ol class="list mt16 mb16">
            <li>Você não receberá mais cobranças;</li>            
            <li>Seus limites e funcionalidades voltam para os <a href="https://help.respondi.app/article/87-limites-de-cada-plano" target="_blank" class="link">limites do plano gratuíto</a>;</li>
            <li>Formulários com funcionalidades não disponíveis no plano grátis serão <strong>bloqueados</strong> até que essa função seja desativada por você;</li>
            <li>As suas respostas não serão excluídas por um período de <strong>até 6 meses</strong>, possibilitando que você continue de onde parou no futuro;</li>
        </ol>

        <div class="row">
            <Btn @click.native="confirmCancelSubscription" :loading="working.cancel" class="mr16" size="sm" type="danger">Cancelar assinatura</Btn>
            <Btn @click.native="modalClose" size="sm" type="secondary">Manter assinatura atual</Btn>
        </div>

        <Callout v-if="error.cancel" type="danger" class="mt16" emoji="🐞">
            Infelizmente tivemos um erro ao cancelar sua assinatura. Por favor, entre em contato com o nosso suporte para cancelar manualmente.
        </Callout>
    </div>
</template>


<style lang="sass" scoped>
.image
    max-width: 250px
.h2
    font-size: $fzMd

.list
    list-style: decimal
    padding-left: $mgSm
    line-height: 120%

    li
        margin-bottom: $mgXs


</style>